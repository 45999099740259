import { ref, inject } from "vue";
import api from "@/services/userApi";

export default function useDomains() {
  const t = inject("t");
  const domain = ref({
    domain: "",
    regexp: "",
    country: "",
  });
  const domains = ref([]);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND Domains
  const findDomains = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/domain");

      if (response?.data) {
        const mappedDomains = response.data.map((item) => {
          return {
            ...item,
            updated_at: new Date(item.updated_at),
            created_at: new Date(item.created_at),
          };
        });

        domains.value = mappedDomains;
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   FIND Domain
  const findDomain = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/domain/${id}`);

      if (response?.data?.length) {
        domain.value = response.data[0];
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   CREATE Domain
  const createDomain = async (data) => {
    try {
      const response = await api.post(`/admin/xpbx/settings/domain`, data);

      if (response?.data?.id) {
        domains.value.push(response.data);
        n("notify.xpbx.create_domain", "success");
      } else {
        n("notify.xpbx.error_create_domain");
      }
    } catch (error) {
      n("notify.xpbx.error_create_domain");
    }
  };

  //   UPDATE Domain
  const updateDomain = async (data, id) => {
    try {
      const response = await api.put(`/admin/xpbx/settings/domain/${id}`, data);

      if (response?.data?.id) {
        const index = domains.value.findIndex((i) => i.id === id);
        domains.value[index] = response.data;
        n("notify.xpbx.edit_domain", "success");
      } else {
        n("notify.xpbx.error_edit_domain");
      }
    } catch (error) {
      n("notify.xpbx.error_edit_domain");
    }
  };

  //   DELETE Domain
  const deleteDomain = async (id) => {
    try {
      const response = await api.delete(`/admin/xpbx/settings/domain/${id}`);

      if (response.status === 200) {
        n("notify.xpbx.delete_domain", "success");

        domains.value = domains.value.filter((i) => i.id !== id);
      } else {
        n("notify.xpbx.error_delete_domain");
      }
      return response;
    } catch (error) {
      n("notify.xpbx.error_delete_domain");
      return error;
    }
  };

  // DELETE DOMAINS
  const deleteDomains = async (ids) => {
    for (const item of ids) {
      try {
        const response = await api.delete(
          `/admin/xpbx/settings/domain/${item?.id}`
        );
        if (response.status === 200) {
          domains.value = domains.value.filter((i) => i.id !== item.id);
        }
      } catch (error) {
        n("notify.xpbx.error_delete_template");
      }
    }

    n("notify.xpbx.delete_template", "success");
  };

  return {
    domain,
    domains,
    findDomains,
    findDomain,
    createDomain,
    updateDomain,
    deleteDomain,
    deleteDomains,
  };
}
