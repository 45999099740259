<template>
  <div class="bg-white w-full p-3">
    <section class="h-full w-full">
      <PageHeader
        :heading="$t('xpbx.pages.heading.domains')"
        :showButton="false"
        :buttonTitle="$t('xpbx.button.create_domain')"
      />

      <!-- Domains table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="domains"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          v-if="domains && domains?.length"
          :paginator="true"
          :rows="10"
          removableSort
          v-model:selection="selectedRecords"
          v-model:filters="filters"
          paginatorPosition="top"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} domains"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          dataKey="id"
          selectionMode="single"
          :rowClass="rowClass"
          @rowSelect="onRowSelect"
          @rowUnselect="onRowUnselect"
          :globalFilterFields="['domain']"
        >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  icon="pi pi-plus"
                  severity="success"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
                <Button
                  :label="$t('xpbx.button.delete')"
                  icon="pi pi-trash"
                  severity="danger"
                  class="delete-button"
                  @click="confirmDeleteSelected"
                  :disabled="!selectedRecord?.id"
                />
              </div>
              <div class="flex gap-2 items-center ml-auto">
                <IconField iconPosition="left">
                  <InputIcon>
                    <i class="pi pi-search" />
                  </InputIcon>
                  <InputText
                    v-model="filters['global'].value"
                    :placeholder="$t('xpbx.placeholders.search')"
                  />
                </IconField>
                <Export
                  :dt="dt"
                  :tableData="domains"
                  :columns="domainHeaders"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="domain"
            style="width: 25%"
            :header="$t('xpbx.table-field.domain')"
          >
            <template #body="{ data }">
              {{ data?.domain }}
            </template>
          </Column>
          <Column
            sortable
            field="regexp"
            style="width: 30%"
            :header="$t('xpbx.table-field.regexp')"
          >
            <template #body="{ data }">
              {{ data?.regexp }}
            </template>
          </Column>
          <Column
            sortable
            field="country"
            style="width: 15%"
            :header="$t('xpbx.table-field.country')"
          >
            <template #body="{ data }">
              {{ data?.country }}
            </template>
          </Column>

          <Column
            sortable
            field="created_at"
            filterField="created_at"
            dataType="date"
            style="width: 15%"
            :header="$t('xpbx.table-field.created_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.created_at" />
            </template>
          </Column>
          <Column
            sortable
            dataType="date"
            field="updated_at"
            filterField="updated_at"
            style="width: 15%"
            :header="$t('xpbx.table-field.updated_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.updated_at" />
            </template>
          </Column>
        </DataTable>
        <Loader v-else />
      </div>

      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="selectedRecord?.domain">
            {{
              $t("notify.xpbx.message.confirm_delete", {
                delete: `domain ${selectedRecord?.domain}`,
              })
            }}</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            text
            @click="deleteRecordsDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            text
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create template -->
      <Dialog
        v-model:visible="domainDialog"
        :style="{ width: '700px' }"
        :header="
          isEdit
            ? $t('xpbx.pages.heading.edit_domain')
            : $t('xpbx.pages.heading.create_domain')
        "
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!-- Contry -->
        <div class="field mb-1 select-autocomplete relative">
          <label for="description mb-2">{{
            $t("label.profile.profileinfo-pwd.body.company-info.country")
          }}</label>
          <SelectWithSearch
            v-model="domain.country"
            :options="countryOptions"
            :bordered="true"
            name="country"
            id="country"
            :errors="errors.country"
            :footerLabel="
              $t('label.profile.profileinfo-pwd.body.company-info.country')
            "
          />
        </div>
        <QueueHint :title="$t('xpbx.hints.domains.country')" />

        <!--Domain -->
        <div class="field mb-1">
          <label for="description mb-2">{{
            $t("xpbx.placeholders.domain")
          }}</label>
          <InputText
            id="description"
            v-model.trim="domain.domain"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !domain.domain }"
          />
          <small class="p-error" v-if="!!errors?.domain?.[0]">{{
            errors?.domain?.[0]
          }}</small>
        </div>
        <QueueHint :title="$t('xpbx.hints.domains.domain')" />

        <!-- Regex -->
        <div class="field mb-1">
          <label for="regex mb-2">{{ $t("xpbx.placeholders.regex") }}</label>
          <InputText
            id="regex"
            v-model.trim="domain.regexp"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !domain.regex }"
          />
          <small class="regex-example"
            >Example: ^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-.
            ]*(\\d{4})(?: *x(\\d+))?\\s*$</small
          >
        </div>
        <QueueHint :title="$t('xpbx.hints.domains.regexp')" />

        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            text
            @click="domainDialog = false"
          />
          <Button label="Save" icon="pi pi-check" text @click="create" />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch, onMounted, computed } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import useDomains from "@/modules/xpbx/composables/useDomains";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/date-item/index.vue";
import { validateCreateDomain } from "@/composables/auth/createDomainValidations";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Calendar from "primevue/calendar";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import Export from "@/modules/xpbx/pages/settings/components/export/index.vue";
import { domainHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "SettingDomains",

  components: {
    PageHeader,
    DateItem,
    DataTable,
    Column,
    Loader,
    Button,
    InputText,
    Dialog,
    IconField,
    InputIcon,
    Calendar,
    Export,
    QueueHint,
  },

  setup() {
    const store = useStore();
    // Datatable
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const domainDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const defaultRegexp = ref(
      "^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$"
    );
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const countries = computed(() => store.getters.countries);

    const countryOptions = computed(() => {
      return countries.value.map((country) => {
        return {
          name: country.country_name,
          value: country.id,
        };
      });
    });

    const {
      findDomains,
      deleteDomain,
      domains,
      createDomain,
      updateDomain,
      domain,
    } = useDomains();

    // Data table functions
    const openNew = () => {
      domain.value = {
        description: "",
        message: "",
        channel: "SMS",
      };
      isEdit.value = false;
      domainDialog.value = true;
    };

    const closeDialog = (value) => {
      domainDialog.value = value;
    };

    const reset = () => {
      domain.value = {
        domain: "",
        regexp: "",
        country: "",
      };
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const create = async () => {
      const isValid = validateCreateDomain(domain.value);

      if (isValid) {
        if (isEdit.value === true) {
          const countryCodeLength = domain.value.country.length;
          let countryCode = domain.value.country;

          if (countryCodeLength > 2) {
            const country = countries.value.find(
              (i) => i.country_name === domain.value.country
            );
            countryCode = country?.cc_iso || "";
          }

          console.log("countryCode", countryCode);

          const form = {
            ...domain.value,
            country: countryCode,
            regexp: domain.value?.regexp || defaultRegexp?.value,
          };

          if (!countryCode) return;
          await updateDomain(form, domain.value.id);
        } else {
          const country = countries.value.find(
            (i) => i.country_name === domain.value.country
          );

          const countryCode = country?.cc_iso || null;

          if (!countryCode) {
            reset();
            return;
          }
          const form = {
            domain: domain.value.domain,
            country: countryCode,
            regexp: domain.value?.regexp || defaultRegexp?.value,
          };

          await createDomain(form);
        }

        isEdit.value = false;
        domainDialog.value = false;
      }
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteDomain(selectedRecords.value.id);
      deleteRecordsDialog.value = false;
    };

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const onRowUnselect = (event) => {
      if (selectedRecord.value?.id === event.data.id) {
        let countryName = event.data.country;
        const country = countries.value.find(
          (i) => i.cc_iso === event.data.country
        );

        if (country) countryName = country.country_name;
        const newDomain = {
          ...event.data,
          country: countryName,
        };
        domain.value = newDomain;
        isEdit.value = true;
        domainDialog.value = true;
      }
    };

    const rowClass = (data) => {
      return [
        {
          "table-selected": isEdit.value && domain?.value?.id === data?.id,
        },
      ];
    };

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    watch(domainDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      checkTableClick();
      await findDomains();
    });

    return {
      dt,
      isEdit,
      errors,
      domain,
      domainHeaders,
      domainDialog,
      selectedRecord,
      countryOptions,
      selectedRecords,
      deleteRecordsDialog,
      defaultRegexp,
      filters,
      submitted,
      findDomains,
      domains,
      scrollHeight,
      openNew,
      closeDialog,
      create,
      onRowSelect,
      rowClass,
      onRowUnselect,
      confirmDeleteSelected,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.regex-example {
  color: #10b981;
  margin-top: 4px;
  display: inline-block;
}
</style>